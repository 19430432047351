import React, { useContext } from "react";
import { Link, NavLink } from 'react-router-dom';
import { Navbar, Nav, Container, Dropdown } from "react-bootstrap";
import "./Navigation.css";
import { useTranslation } from 'react-i18next';

import proxy from '../../setup/proxy.js';
import axios from 'axios';

// components
import LanguageSelector from "../utilities/LanguageSelector.js";

// import bootstrap
import 'bootstrap/dist/css/bootstrap.min.css';

//import { DownloadImages } from "../services/imageService";


//stores
import useTranslationStore from "../../stores/TranslationStore.js";


// popup
import { toast } from 'react-toastify';

const LandingPageNavigation = ({ pageKey, isManualOffer, sufixValue}) => {
  // console.log("sufix", sufixValue);
  // console.log("pageKey", pageKey);
  

    // ustawienie tłumaczeń
    const { clientLang, setClientLang } = useTranslationStore();
    const { t } = useTranslation('landing_page');

  
    const handleLanguageChange = (lang) => {
      setClientLang(lang);
    };

  const isReady = useTranslationStore((state) => state.isReady);

  const lang = clientLang;

  // middle address
  const middleAddress = isManualOffer ? "" : "offer/";

  if (isManualOffer) pageKey = sufixValue


  // const handleDownloadVisuals = (event) => {
    
  //   // console.log("landingPageSkuList", landingPageSkuList);
  //   if (landingPageSkuList.length > 0) {
  //     const skus = landingPageSkuList.map(item => item.sku); // Wyciągnięcie SKU z obiektów
  //     DownloadImages(skus, 0, showPopupSmall);
  //   } else {
  //     // console.log('No SKUs available');
  //   }
  // };

  const handleDownloadPresentation = async (event) => {
    const storedSkus = sessionStorage.getItem("selectedSkus");

    if (!storedSkus || storedSkus.length === 0 || storedSkus === "[]") {
      toast.error(t('no_products_selected'));
      return null;
    }

    toast(t('presentation_prep_popup'));

    if (storedSkus.length > 0) {
     // const skus = landingPageSkuList.map(item => item.sku); // Wyciągnięcie SKU z obiektów
      // GetExcelOffer(skus, showPopupSmall); // dodaj lang w przyszłości

      try {
        const response = await axios.post(`${proxy}/api/landing-page/generate-offer-pptx`, {
          skus: storedSkus,
          lang: clientLang
        }, {
          responseType: 'arraybuffer'
        });
  
      
       // Odebranie pliku pptx
       const url = window.URL.createObjectURL(new Blob([response.data], { type: 'application/vnd.openxmlformats-officedocument.presentationml.presentation' }));
       const link = document.createElement('a');
       link.href = url;
       link.setAttribute('download', 'CompactorOffer.pptx'); // Ustaw nazwę pliku
       document.body.appendChild(link);
       link.click();
       link.remove();
     } catch (error) {
      toast.error(t('error_occured'));
     }
   } else {
     // console.log('No SKUs available');
      toast.error(t('no_products_selected'));
   }
 }

  if (!isReady) return <p>Loading translations...</p>;
 return (
  <Navbar bg="light" expand="lg" className="sticky-top nav-bar-top shadow">
    <Container fluid>
      <div className="navbar-brand-container">
        <Navbar.Brand as={Link} to={`/offer/${pageKey}`}>
          <img
            src={`${proxy}/content/logos/compactor-logo.png`}
            className="nav-bar-top-logo"
            alt="Compactor logo"
          />
        </Navbar.Brand>

      </div>
   
      <Navbar.Toggle aria-controls="basic-navbar-nav" />
      <Navbar.Collapse id="basic-navbar-nav">
        <Nav className="mx-auto">
          <Nav.Link as={Link} to={`/${middleAddress}${pageKey}`}>{t('catalog')}</Nav.Link>
          <Nav.Link onClick={handleDownloadPresentation}>{t('export_pptx')}</Nav.Link>

          <Nav.Link as={Link} to={`/${middleAddress}${pageKey}/contact`}>{t('contact')}</Nav.Link>
        </Nav>
        <div className="store-link-container d-flex">
          <LanguageSelector selectedLanguage={lang} onChangeLanguage={handleLanguageChange} />
          <Nav>
            <Nav.Link href="https://compactorstore.com" target="_BLANK" className="store-link">{t('visit_store')}</Nav.Link>
          </Nav>
        </div>
      </Navbar.Collapse>
    </Container>
  </Navbar>
);
  };
  

export default LandingPageNavigation;
