import axiosInstance from "../setup/axiosInstance";

export async function loginLandingPage(password, pageKey) {
    try{
        const result = await axiosInstance.post("/api/landing-page/login", {
            password,
            pageKey,
        });
        // console.log("result login landing page", result);
        return result;
    }catch(error){
        // console.log("error", error);
        throw error;
    }



}