import React, { useState, useEffect } from "react";
import { Container, Row, Col } from "react-grid-system";

import SKUList from "../../components/SKUlist.js";
import ProductImagesToSort from "../../components/ProductImagesDraggable.js";
// zapimportuj app.css
import "../../styles/styles.css";
import { getAllSkusList } from "../../services/skusService.js";

// translations
import { useTranslation } from 'react-i18next';
import  useTranslationStore from '../../stores/TranslationStore.js';

function ImageOrder() {
  const [selectedSKU, setSelectedSKU] = useState("");
  const [skuList, setSkuList] = useState([]);

  //translations setup
  const {t} = useTranslation('common');
  const isReady = useTranslationStore((state) => state.isReady);

   

  useEffect(() => {
    const fetchSkus = async () => {
      try {
        const skus = await getAllSkusList();
        setSkuList(skus);
      } catch (error) {
        console.error("Error fetching SKU list:", error);
      }
    };

    fetchSkus();
  }, []);



  const handleSKUSelect = (sku) => {
    // console.log("wybrany sku: ", sku);
    setSelectedSKU(sku);
    //markSelectedSKU(sku);

  };

  if(!isReady) return <p>Loading translations...</p>;
  return (
    <Container fluid>
      <Row>
        <Col sm={2}>
          <SKUList onSKUSelect={handleSKUSelect} showFilterManualOrder={true} showVisualsQuantity={true} showFilterPhotosAvailable={true} showFilterVideosAvailable={true} skuList={skuList}/>
        </Col>
        <Col sm={8} className={"tiles-container"}>
          <ProductImagesToSort
            selectedSKU={selectedSKU}
            className={"image-tiles-draggable"}
            t={t}
          />
        </Col>
        <Col sm={2}>
          {/* <SKUList onSKUSelect={handleSKUSelect} /> */}
        </Col>
      </Row>
    </Container>
  );
}

export default ImageOrder;