import React, { useState, useEffect } from 'react';
import { Form, Button, Container, Row, Col, Card} from 'react-bootstrap';
import axiosInstance from '../../setup/axiosInstance';
import proxy from '../../setup/proxy';

const DescriptionGetForm = () => {
  const [sku, setSku] = useState('');
  const [languages, setLanguages] = useState([]);
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [targetPages, setTargetPages] = useState([]);
  const [selectedTargetPages, setSelectedTargetPages] = useState([]);

  useEffect(() => {
    // Fetch languages
    axiosInstance.get(`${proxy}/api/products/desc/languages`)
      .then(response => {
        setLanguages(response.data);
      })
      .catch(error => {
        console.error('Error fetching languages:', error);
      });

    // Fetch target pages
    axiosInstance.get(`${proxy}/api/products/desc/target-pages`)
      .then(response => {
        setTargetPages(response.data);
      })
      .catch(error => {
        console.error('Error fetching target pages:', error);
      });
  }, []);

  const handleSkuChange = (e) => {
    setSku(e.target.value);
  };

  const handleLanguageChange = (lang) => {
    setSelectedLanguages((prevSelected) =>
      prevSelected.includes(lang)
        ? prevSelected.filter((item) => item !== lang)
        : [...prevSelected, lang]
    );
  };

  const handleTargetPageChange = (page) => {
    setSelectedTargetPages((prevSelected) =>
      prevSelected.includes(page)
        ? prevSelected.filter((item) => item !== page)
        : [...prevSelected, page]
    );
  };

  const handleSelectAllLanguages = (checked) => {
    setSelectedLanguages(checked ? languages.map(lang => lang.code) : []);
  };

  const handleSelectAllTargetPages = (checked) => {
    setSelectedTargetPages(checked ? targetPages.map(page => page.code) : []);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const skuArray = sku.split(/[\n,]+/).map(item => item.trim()).filter(item => item);
    const data = {
      skuList: skuArray,
      langList: selectedLanguages,
      targetPageList: selectedTargetPages
    };
    // console.log('Form Data:', data);

    try{
        const result = await axiosInstance.post(`${proxy}/api/products/desc/export-csv`, data, {
            responseType: 'blob',
            headers: {
            'Content-Type': 'application/json',
            },
        });
      // console.log('Result:', result);
  
      const blob = new Blob([result.data], { type: result.headers['content-type'] });
      const url = window.URL.createObjectURL(blob);
      const a = document.createElement('a');
      a.href = url;
      a.download = `description-export-${new Date().toISOString()}.xlsx`;
      document.body.appendChild(a); // Dodaj element do dokumentu
      a.click();
      document.body.removeChild(a); // Usuń element po kliknięciu

    } catch (error) {
      console.error('Error downloading CSV:', error);
    }
  };

  const isSubmitDisabled = !sku || selectedLanguages.length === 0 || selectedTargetPages.length === 0;

  return (
    <Container>
      <Row className="justify-content-md-center mt-5">
        <Col md={8}>
          <Card className="shadow">
            <Card.Header>
              <h2 className="text-center">Export descriptions to Excel </h2>
            </Card.Header>
            <Card.Body>
              <Form onSubmit={handleSubmit}>
                <Row>
                  <Col md={6}>
                    <Form.Group controlId="skuInput">
                      <Form.Label>Product SKU(s)</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={5}
                        value={sku}
                        onChange={handleSkuChange}
                        placeholder="Enter product SKUs separated by new lines or commas"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                  <Form.Group>
                    <div className="d-flex flex-row justify-content-between">
                        <Form.Label>Target Pages</Form.Label>
                      <Form.Check
                        type="switch"
                        id="select-all-target-pages"
                        label="Select/Unselect All"
                        checked={selectedTargetPages.length === targetPages.length}
                        onChange={(e) => handleSelectAllTargetPages(e.target.checked)}
                        className="mb-2 px-5"
                      /></div>
                      
                      {targetPages.map((page) => (
                        <Form.Check
                          type="switch"
                          id={`page-${page.code}`}
                          key={page.code}
                          label={page.name}
                          checked={selectedTargetPages.includes(page.code)}
                          onChange={() => handleTargetPageChange(page.code)}
                          className="mb-2"
                        />
                      ))}
                    </Form.Group>
                    <hr className="hr"></hr>
                    <Form.Group>
                    <div className="d-flex flex-row justify-content-between">
                      <Form.Label>Languages</Form.Label>
                      <Form.Check
                        type="switch"
                        id="select-all-languages"
                        label="Select/Unselect All"
                        checked={selectedLanguages.length === languages.length}
                        onChange={(e) => handleSelectAllLanguages(e.target.checked)}
                        className="mb-2 px-5"
                      />
                      </div>
                      {languages.map((lang) => (
                        <Form.Check
                          type="switch"
                          id={`lang-${lang.code}`}
                          key={lang.code}
                          label={lang.name}
                          checked={selectedLanguages.includes(lang.code)}
                          onChange={() => handleLanguageChange(lang.code)}
                          className="mb-2"
                        />
                      ))}
                    </Form.Group>
                   
                  </Col>
                </Row>
                <Button variant="primary" type="submit" disabled={isSubmitDisabled} className="mt-3">
                  Submit
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Container>
  );
};

export default DescriptionGetForm;
