// src/hooks/useLoadTranslations.js

import { useQuery } from '@tanstack/react-query';
import React from 'react';
import { useEffect, useRef } from 'react';
import i18n from '../setup/i18n';
import { fetchTranslations } from '../services/translationService.js';
import useTranslationStore from '../stores/TranslationStore';

const useLoadTranslations = (lng, ns) => {
    const isReady = useTranslationStore((state) => state.isReady);
    const setLoading = useTranslationStore((state) => state.setLoading);
    const setReady = useTranslationStore((state) => state.setReady);
    
    // Flaga lokalna, aby uniknąć wielokrotnego ustawiania stanu
    const hasInitialized = useRef(false);
    const queryKey = React.useMemo(() => ['translations', lng, ns], [lng, ns]);

  
    const query = useQuery({
      queryKey: ['translations', lng, ns],
      queryFn: () => {

        return fetchTranslations({ queryKey });
      },
      enabled: !!lng && !!ns,
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
      // onSuccess: (data) => // console.log('Translations fetched:', data), // Log po sukcesie
      // onError: (error) => console.error('Error fetching translations:', error), // Log błędu
    });

    useEffect(() => {
      if (!query.isFetching && !!lng && !!ns) {
        query.refetch();
      }
    }, [lng, ns]);
  
    const { data, isLoading, error } = query;

    // // console.log('useLoadTranslations', lng, ns, isLoading, error, data); // Debug log  

    useEffect(() => {
      if (isLoading) {
        setLoading(true); // Ustaw stan ładowania

      } 
      if (!isLoading && !error && !isReady && !hasInitialized.current) {
        setLoading(false); // Zatrzymaj ładowanie
        setReady(true); // Oznacz jako gotowe
        hasInitialized.current = true; // Oznacz jako zainicjalizowane

      }
  
      if (data) {
        i18n.addResourceBundle(lng, ns, data, true, true);
        if (i18n.language !== lng) {
          i18n.changeLanguage(lng);
        }
      }
    }, [data, isLoading, error, lng, ns, isReady, setLoading, setReady]);
  
    return { isLoading, error };
  
  };
  
  export default useLoadTranslations;