import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPageLayout from "../layouts/LandingPageLayout";
import LandingPage from "../pages/public/LandingPage";
import Contact from "../pages/public/ContactPage";

const CseRoutes = () => {
    const manualPageKey = "l4h95n9";
  return (
    <LandingPageLayout>
      <Routes>
           <Route path="/contact" element={<LandingPage manualPageKey={manualPageKey} isContact={true} sufix="cse" />} />
           <Route path="/*" element={<LandingPage manualPageKey={manualPageKey} isContact={false} sufix="cse" />} />
      </Routes>
    </LandingPageLayout>
  );
};

export default CseRoutes;
