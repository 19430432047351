// import React, { useState, useEffect, useContext } from "react";
// import {
//   BrowserRouter as Router,
//   Route,
//   Routes,
//   Navigate,
//   useLocation,
//   useNavigate
// } from "react-router-dom";
// import Home from "./pages/admin/Home";
// import ImageOrder from "./pages/admin/ImageOrder";
// import ShowImagesResize from "./pages/admin/BrowseVisuals";
// import Login from "./components/Login";
// import { Navigation } from "./pages/admin/Navigation";

// import { PopupProvider } from "./context/PopupContext"; 
// import { GetProductsData } from "./pages/admin/GetProductsData";
// import LandingPage from "./pages/public/LandingPage";
// import Footer from "./components/Footer";
// //description pages
// import DescriptionsEditor from "./pages/admin/DescriptionsEditor";
// import DescriptionGetForm from "./pages/admin/DescriptionsGetForm";
// import DescriptionsBulkGeneration from "./pages/admin/DescriptionsBulkGeneration";

// import LandingPageEditor from "./pages/admin/LandingPageEditor";
// import ScrollToTop from "./components/utilities/ScrollToTop";
// import UsersEditor from "./pages/admin/UsersEditor";
// import Changelog from "./pages/admin/Changelog";
// import ProductsBrowser from "./pages/admin/ProductsBrowser";
// import AdminControl from "./pages/admin/AdminControl";
// import {SkuEanConverter} from "./pages/admin/SkuEanConverter";

// import UpdateProductsData from "./pages/admin/UpdateProductsData";

// import Maintenance from "./pages/admin/Maintenance";

// // test pages
// import TestPageAdmin from "./pages/admin/TestPageAdmin";
// import TestPageClient from "./pages/admin/TestPageClient";

// import axiosInstance from "./setup/axiosInstance";
// import proxy from "./setup/proxy";





// // import styles
// import "bootstrap/dist/css/bootstrap.min.css";
// import "./App.css";

// // Importowanie plików do tłumaczeń
// import { TranslationProvider } from './context/TranslationContext';


// // Importowanie kontekstu autoryzacji
// import { AuthProvider, AuthContext } from './context/AuthContext';

// const MainLayout = ({ children, isLoggedIn }) => {
//   const location = useLocation();
//   const isOfferPage = location.pathname.startsWith('/offer');

//   return (
//     <div className="main-container">
//       {isOfferPage ? "" : isLoggedIn ? <Navigation /> : ""}
//       {children}
//     </div>
//   );
// };


// const AppContent = () => {
//   const { isLoggedIn, isLoading } = useContext(AuthContext);

//   if (isLoading) {
//     return <div>Loading...</div>;  // Wyświetlamy loader do momentu załadowania
//   }

//   return (
//     <Router>
//       <BackNavigationHandler />
//       <PopupProvider>
//         <ScrollToTop />
//         {/* Przekazujemy zawartość jako children do MainLayout */}
//         <MainLayout isLoggedIn={isLoggedIn}>
//           {/* <CheckMaintenance setIsUnderMaintenance={setIsUnderMaintenance} /> */}
//           <Routes>
//             <Route path="/maintenance" element={<Maintenance />} />
//             <Route path="/offer/:page_key/:sku" element={<LandingPage />} />
//             <Route path="/offer/:page_key" element={<LandingPage />} />
//             <Route path="/offer" element={<LandingPage />} />
//             <Route path="/offer/:page_key/contact" element={<LandingPage />} />
//             <Route path="/" element={isLoggedIn ? <Home /> : <Navigate to="/login" />} />
//             <Route path="/image-order" element={isLoggedIn ? <ImageOrder /> : <Navigate to="/login" />} />
//             <Route path="/image-show" element={isLoggedIn ? <ShowImagesResize /> : <Navigate to="/login" />} />
//             <Route path="/get-products-data" element={isLoggedIn ? <GetProductsData /> : <Navigate to="/login" />} />
//             <Route path="/update-products-data" element={isLoggedIn ? <UpdateProductsData /> : <Navigate to="/login" />} />
//             <Route path="/products-browser" element={isLoggedIn ? <ProductsBrowser /> : <Navigate to="/login" />} />
//             <Route path="/sku-ean-converter" element={isLoggedIn ? <SkuEanConverter /> : <Navigate to="/login" />} />
//             <Route path="/descriptions-editor" element={isLoggedIn ? <DescriptionsEditor /> : <Navigate to="/login" />} />
//             <Route path="/descriptions-export-excel" element={isLoggedIn ? <DescriptionGetForm /> : <Navigate to="/login" />} />
//             <Route path="/descriptions-bulk-generation" element={isLoggedIn ? <DescriptionsBulkGeneration /> : <Navigate to="/login" />} />
//             <Route path="/landing-page-editor/:page_key?" element={isLoggedIn ? <LandingPageEditor /> : <Navigate to="/login" />} />
//             <Route path="/users-editor" element={isLoggedIn ? <UsersEditor /> : <Navigate to="/login" />} />
//             <Route path="/admin-control" element={isLoggedIn ? <AdminControl /> : <Navigate to="/login" />} />
//             <Route path="/login" element={!isLoggedIn ? <Login /> : <Navigate to="/" replace />} />
//             <Route path="/changelog" element={isLoggedIn ? <Changelog /> : <Navigate to="/login" />} />
//             <Route path="/test-page-admin" element={isLoggedIn ? <TestPageAdmin /> : <Navigate to="/login" />} />
//             <Route path="/test-page-client" element={isLoggedIn ? <TestPageClient /> : <Navigate to="/login" />} />
//             <Route path="*" element={<Navigate to="/" />} />
//           </Routes>
//         </MainLayout>
//       </PopupProvider>
//       <Footer />
//     </Router>
//   );
// };







//   const App = () => (
 

//       <AuthProvider>
//         <AppContent />
//       </AuthProvider>

  
//   );


import React from "react";
import { useEffect, useContext } from "react";
import { BrowserRouter as Router, Route, Routes, useNavigate, Navigate } from "react-router-dom";
import AdminRoutes from "./routes/AdminRoutes";
import LandingPageRoutes from "./routes/LandingPageRoutes";
import CseRoutes from "./routes/CseRoutes";
import RetailRoutes from "./routes/RetailRoutes";

import Login from "./components/Login";
import { AuthContext } from "./context/AuthContext";

// pop-upy
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import './App.css'

const App = () => {

  const { isLoggedIn, isLoading } = useContext(AuthContext);

  useEffect(() => {

  }, [isLoggedIn]);

  if (isLoading) {
    return <div>Loading...</div>; // tutaj może być spinner, loader, cokolwiek
  }

  //      {/* <BackNavigationHandler /> */}
  return (


      <Router>

        <Routes>
          {/* Publiczne ścieżki */}
          <Route path="/offer/*" element={<LandingPageRoutes />} />
          <Route path="/cse/*" element={<CseRoutes />} />
          <Route path="/retail/*" element={<RetailRoutes />} />
          {/* Panel administracyjny */}
          <Route path="/*" element={<AdminRoutes />} />
          {/* Login */}
          <Route path="/login" element={!isLoggedIn ? <Login /> : <Navigate to="/" replace />} />

        </Routes>
        <ToastContainer
          position="top-right"
          autoClose={5000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
        />

        
      </Router>

  );
};

export default App;


