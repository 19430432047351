import React, { useState, useContext, useEffect, useCallback } from "react";
import { generateContent, saveDescriptionData, fetchQueryData } from "../../services/descriptionService";
import "bootstrap/dist/css/bootstrap.min.css";
import { Spinner, Tab, Nav, Card } from 'react-bootstrap';

import { CategoryDivider } from "../visual/headers.js";
import { AuthContext } from "../../context/AuthContext";

import useDescriptionStore from "../../stores/DescriptionStore";
import { set } from "react-hook-form";
import { useConfirm } from "../../hooks/useConfirm.js"

const {generateKeywords, generateDescription} = require("../../services/descriptionService");


const Descriptions = ({ sku, handleDescriptionData, refreshFlagToggle }) => {
  const [activeKey, setActiveKey] = useState('amazon-fr');
  const [localData, setLocalData] = useState({});


  const { confirm, modal } = useConfirm();

  const [isGenerating, setIsGenerating] = useState(false);
  const [selectedTpCode, setSelectedTpCode] = useState('amazon');
  const [selectedLangCode, setSelectedLangCode] = useState('fr');
  const [localQueryData, setLocalQueryData] = useState({}); // wypełnij danymi z zustand później
  const [showQueryFields, setShowQueryFields] = useState(false);

  const [data, setData] = useState(null);

  const {descriptions, targetPages, languages, globalData} = useDescriptionStore();

  useEffect(() => {
    setData(descriptions);
    setLocalQueryData(globalData);
  }, [descriptions, globalData]);



  // set the current target page and language
  useEffect(() => {
    if (!activeKey && targetPages && languages) {
      setActiveKey(`${targetPages[0].code}-${languages[0].code}`);
    }
  }, [data]);

  // check local data
  useEffect(() => {
    // console.log("localData", localData);
  }, [localData]);

  useEffect(() => {
    if (activeKey) {
      const [type, lang] = activeKey.split('-');
      setSelectedTpCode(type);
      setSelectedLangCode(lang);
  
      // Pobierz dane, jeśli istnieją
      if (data && data[type] && data[type][lang]) {
        let bulletpoints = data[type][lang].bulletpoints || [];
      
        // Dodaj puste elementy, jeśli jest mniej niż 5
        if (bulletpoints.length < 5) {
          bulletpoints = [...bulletpoints, ...Array(5 - bulletpoints.length).fill("")];
        }
      
        // Usuń nadmiarowe elementy, jeśli jest więcej niż 5
        if (bulletpoints.length > 5) {
          bulletpoints = bulletpoints.slice(0, 5);
        }
      
        setLocalData({
          ...data[type][lang],
          bulletpoints, // Zaktualizowana tablica bulletpoints
        });
      } else {
        // Zresetuj wartości, jeśli dane nie istnieją
        setLocalData({
          bulletpoints: Array(5).fill(""), // Tablica 5 pustych elementów
        });
      }
    }
  }, [activeKey, data]);

  // generating keywords
  const handleGenerateKeywords = useCallback(() => {
    const generate = async () => {
      try {   
        setIsGenerating(true);

        const language = languages.find((obj) => obj.code === selectedLangCode);
        if (!language) {
          throw new Error(`Language with code '${selectedLangCode}' not found.`);
        }

        const targetPage = targetPages.find((obj) => obj.code === selectedTpCode);
        if (!targetPage) { 
          throw new Error(`Target page with code '${selectedTpCode}' not found.`);
        }
        
        if (!globalData.productData.business_description) {
          throw new Error("Business description is missing.");
        }
        if (!globalData.commercialCategory.product_type) {
          throw new Error("Product type is missing.");
        }
        if (!globalData.commercialCategory.highlights) {
          throw new Error("Highlights are missing.");
        }
  
        // Przygotowanie danych do wysłania
        const data = {
          targetPage: targetPage.name,
          businessDescription: globalData.productData.business_description,
          productType: globalData.commercialCategory.product_type,
          highlights: globalData.commercialCategory.highlights,
          language: language.name,
        };
  
        // console.log("Sending data to generate keywords:", data);
  
        // Wysyłanie zapytania do backendu
        const response = await generateKeywords(data);
  
        if (!response?.data?.keywords || !Array.isArray(response.data.keywords)) {
          throw new Error("Invalid response format from the API.");
        }
  
        // Przetwarzanie odpowiedzi
        const keywordsString = response.data.keywords.join(", ");
  
        // Aktualizacja lokalnych danych
        setLocalData((prevData) => ({
          ...prevData,
          keywords: keywordsString,
        }));
  
        // console.log("Keywords generated successfully:", keywordsString);
      } catch (error) {
        console.error("Error generating keywords:", error.message || error);
      } finally {
        setIsGenerating(false);
      }
    };
  
    generate();
  }, [languages, globalData, selectedTpCode, selectedLangCode]);
  


    // generating description
    const handleGenerateDescription = useCallback(() => {
      const generate = async () => {
        try {
          // console.log("Starting generation");
          setIsGenerating(true);
    
          const language = languages.find((obj) => obj.code === selectedLangCode);
          if (!language) throw new Error(`Language with code '${selectedLangCode}' not found.`);
    
          const targetPage = targetPages.find((obj) => obj.code === selectedTpCode);
          if (!targetPage) throw new Error(`Target page with code '${selectedTpCode}' not found.`);
    
          if (!globalData.productData.business_description) throw new Error("Business description is missing.");
    
          if (!localData.keywords || localData.keywords.trim() === "") {
            alert("Keywords are missing");
            setIsGenerating(false);
            return;
          }
    
          const keywordsCount = localData.keywords.split(",").length;
    
          if ((targetPage.code === "website" || targetPage.code === "website_b2b") && keywordsCount > 1) {
            // console.log("Before confirm");
            const isConfirmed = await confirm("Google recommends using only one keyword for the website per product. Do you want to continue?");
            // console.log("After confirm, isConfirmed:", isConfirmed);
    
            if (!isConfirmed) {
              // console.log("User canceled.");
              setIsGenerating(false);
              return;
            }
          }
    
          const data = {
            sku,
            targetPage: targetPage.code,
            language: language.code,
            businessDescription: globalData.productData.business_description,
            productType: globalData.commercialCategory.product_type || "N/A",
            highlights: globalData.commercialCategory.highlights || "N/A",
            keyFeature: globalData.commercialCategory.key_feature || "N/A",
            productNameAdditionalinfo: globalData.additionalInfo.product_name_additional_info || "N/A",
            commercialCategoryAdditinalInfo: globalData.commercialCategory.commercial_category_additional_info || "N/A",
            keywords: localData.keywords,
          };
    
          const response = await generateDescription(data);
          // console.log("Response from backend:", response);
    
          if (!response || !response.data) {
            throw new Error("Invalid response from backend.");
          }
    
          setLocalData((prevData) => ({
            ...prevData,
            title: response.data.title || "",
            description: response.data.description || "",
            bulletpoints: response.data.bulletpoints || [],
            metadescription: response.data.metadescription || "",
          }));
    
          // console.log("Descriptions generated successfully:", response.data);
        } catch (error) {
          console.error("Error generating description:", error.message || error);
        } finally {
          // console.log("Setting isGenerating to false");
          setIsGenerating(false);
        }
      };
    
      generate();
    }, [languages, globalData, selectedTpCode, selectedLangCode, localData, confirm]);

  const handleCopyBulletpoints = () => {
    const localBulletpoints = localData.bulletpoints.join('\n') || [];
    navigator.clipboard.writeText(localBulletpoints);
  };

  const handleCopy = (field) => {
    if (!localData[field]) {
      // console.log("Brak danych do skopiowania");
      navigator.clipboard.writeText("");
      return;
    }
    navigator.clipboard.writeText(localData[field])
    .then(() => {
      // console.log("Skopiowano do schowka:", localData[field]);
    })
    .catch((error) => {
      console.error("Nie udało się skopiować do schowka:", error);
    });
  };

  const handleInputChange = (field, value) => {
    setLocalData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
       
  };

  const handleBulletpointChange = (index, value) => {
    const newBulletpoints = [...localData.bulletpoints];
    newBulletpoints[index] = value;
    setLocalData((prevData) => ({
      ...prevData,
      bulletpoints: newBulletpoints,
    }));
  };



  const handleSaveChanges = async () => {
    try{
    const data = {
      ...localData,
      sku,
      targetPage: selectedTpCode,
      lang: selectedLangCode,
    }

    const response = await saveDescriptionData(data);
    refreshFlagToggle();
    // console.log("Data saved successfully:", response.data);
  } catch (error) {
    console.error("Error saving data:", error);
  }


    // const [type, lang] = activeKey.split('-');
    // const updatedData = {
    //   sku: sku,
    //   type: type,
    //   bulletpoints: localData.bulletpoints,
    //   description: localData.description,
    //   title: localData.title,
    //   lang: lang,
    //   keywords: localData.keywords,
    //   metadescription: localData.metadescription,
    // };

    // const response = await saveDescriptionData(sku, updatedData);
    // showPopupSmall(`${JSON.stringify(response.data)}`, "success");
  };

  return (
    <div className="container mt-4">
       {modal} {/* Renderuj modal tutaj */}

        <CategoryDivider category="Descriptions generator" />

      <Tab.Container activeKey={activeKey}>
        <Card className="text-center">
          <Card.Header>
            <Nav variant="tabs" className="card-header-tabs justify-content-center">
              {targetPages &&
                targetPages.map((tp) => (
                  <Nav.Item key={tp.code}>
                    <Nav.Link
                      eventKey={tp.code}
                      className={`tab-styles ${tp.code} ${selectedTpCode === tp.code ? 'active' : ''}`}
                      disabled={isGenerating}
                      onClick={() => {
                        setSelectedTpCode(tp.code);
                        setActiveKey(`${tp.code}-${selectedLangCode}`);
                      }}
                    >
                      {tp.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
            </Nav>
          </Card.Header>
          <Card.Body>
            {selectedTpCode && (
              <Nav variant="pills" className="justify-content-center">
                {languages.map((lang) => (
                  <Nav.Item key={`${selectedTpCode}-${lang.code}`}>
                    <Nav.Link
                      eventKey={`${selectedTpCode}-${lang.code}`}
                      className={`tab-styles ${selectedTpCode} ${selectedLangCode === lang.code ? 'active' : ''}`}
                      disabled={isGenerating}
                      onClick={() => setActiveKey(`${selectedTpCode}-${lang.code}`)}
                    >
                      {lang.name}
                    </Nav.Link>
                  </Nav.Item>
                ))}
              </Nav>
            )}
            <Tab.Content>

                <div className="form-group row pb-3 my-3">
                  <hr className="hr hr-blurry" />
                  <div className="col-12 d-flex justify-content-center">
                  <button
                      className="btn btn-secondary mx-2"
                      onClick={handleGenerateKeywords}
                    >
                        {isGenerating ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Generating...
                        </>
                      ) : (
                        "Generate keywords"
                      )}
                    </button>
                    <button
                      className="btn btn-primary mx-2"
                      onClick={handleGenerateDescription}
                      disabled={isGenerating}
                    >
                      {isGenerating ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Generating...
                        </>
                      ) : (
                        "Generate content"
                      )}
                    </button>
                    <button className="btn btn-success mx-2" onClick={handleSaveChanges}>
                    {isGenerating ? (
                        <>
                          <Spinner
                            as="span"
                            animation="border"
                            size="sm"
                            role="status"
                            aria-hidden="true"
                          />
                          Generating...
                        </>
                      ) : (
                        "Save changes"
                      )}
                    </button>
                    
                  </div>
                </div>
              
              {targetPages &&
                targetPages.map((tp) =>
                  languages.map((lang) => (
                    <Tab.Pane eventKey={`${tp.code}-${lang.code}`} key={`${tp.code}-${lang.code}`}>
                      {renderContentForm(tp.code, lang.code)}
                    </Tab.Pane>
                  ))
                )}
            </Tab.Content>
          </Card.Body>
        </Card>
      </Tab.Container>
    </div>
  );

  function renderContentForm() {
    return (
      <div className="mb-5">
        <div className="form-group row">
        <div className="col-sm-2 d-flex flex-column align-items-center">
          <label className="col-form-label">Title:</label>
          <button className="btn btn-outline-secondary btn-sm w-50" onClick={() => handleCopy('title')}>copy</button>
          </div>
          <div className="col-sm-10">
            <textarea
              type="text"
              className="form-control"
              value={localData.title || ''}
              onChange={(e) => handleInputChange('title', e.target.value)}
            />
            <small className="text-muted">
              {localData.title ? localData.title.length : 0} characters
            </small>
          </div>
        </div>
        <div className="form-group row">
          <div className="col-sm-2 d-flex flex-column align-items-center">
          <label className="col-form-label">Keywords:</label>
          <button className="btn btn-outline-secondary btn-sm w-50" onClick={() => handleCopy('keywords')}>copy</button>
          </div>
          
          <div className="col-sm-10">
            <textarea
              type="text"
              className="form-control"
              value={localData.keywords || ''}
              onChange={(e) => handleInputChange('keywords', e.target.value)}
            />
            <small className="text-muted">
              {localData.keywords ? localData.keywords.length : 0} characters
            </small>
          </div>
        </div>
        <div className="form-group row">
        <div className="col-sm-2 d-flex flex-column align-items-center">
          <label className="col-form-label">Bulletpoints:</label>
          <button className="btn btn-outline-secondary btn-sm w-50" onClick={handleCopyBulletpoints}>copy</button>
        </div>
          <div className="col-sm-10">
            {(localData.bulletpoints || []).map((bp, index) => (
              <div key={index}>
                <textarea
                  type="text"
                  className="form-control mb-2 h-90px"
                  value={bp}
                  onChange={(e) => handleBulletpointChange(index, e.target.value)}
                />
                <small className="text-muted">{bp ? bp.length : 0} characters</small>
              </div>
            ))}
          </div>

        </div>
        <div className="form-group row">
        <div className="col-sm-2 d-flex flex-column align-items-center">
          <label className=" col-form-label">Description:</label>
          <button className="btn btn-outline-secondary btn-sm w-50" onClick={() => handleCopy('description')}>copy</button>
        </div>
          <div className="col-sm-10">
            <textarea
              className="form-control h-300px"
              value={localData.description || ''}
              onChange={(e) => handleInputChange('description', e.target.value)}
            />
            <small className="text-muted align-tems-end">
              {localData.description ? localData.description.length : 0} characters
            </small>
          </div>
        </div>
        <div className="form-group row">
        <div className="col-sm-2 d-flex flex-column align-items-center">
          <label className="col-form-label">Metadescription:</label>
          <button className="btn btn-outline-secondary btn-sm w-50" onClick={() => handleCopy('metadescription')}>copy</button>
          </div>
          <div className="col-sm-10">
            <textarea
              type="text"
              className="form-control"
              value={localData.metadescription || ''}
              onChange={(e) => handleInputChange('metadescription', e.target.value)}
            />
            <small className="text-muted">
              {localData.metadescription ? localData.metadescription.length : 0} characters
            </small>
          </div>
        </div>
      </div>
    );
  }
};

export default Descriptions;