// components/DescriptionsGeneral.js

import React, { useEffect} from "react";

// import store
import useDescriptionStore from "../../stores/DescriptionStore";

// import components
import Accordion from "../utilities/Accordion";
import ProductDataSection from "./ProductDataSection.js";
import "bootstrap/dist/js/bootstrap.bundle.min.js";


const DescriptionsGeneral = ({ sku, handleGlobalData }) => {
  const globalData = useDescriptionStore((state) => state.globalData);

  useEffect(() => {
    // console.log("globalData", globalData);

  } , [globalData]);

  const sections = [
    {
      title: "Global Product Data",
      content: (
        <ProductDataSection
          sku={sku}
          globalData={globalData}
          handleGlobalData={handleGlobalData}
        />
      ),
    },
  ];

  return (
    <div className="container">
      <Accordion sections={sections} />
    </div>
  );
};

export default React.memo(DescriptionsGeneral);
 