import React, { useState, useCallback, useEffect, useContext } from "react";
import { useParams } from "react-router-dom";
import "bootstrap/dist/css/bootstrap.min.css";
import proxy from "../../setup/proxy";
import { CategoryDivider } from "../visual/headers.js";
import readXlsxFile from "read-excel-file";
import "./LandingPageForm.css";
import { format } from "date-fns";
import axiosInstance from "../../setup/axiosInstance.js";
// Import AuthContext
import { AuthContext } from "../../context/AuthContext";

// Import i18n
import { useTranslation } from "react-i18next";
import useTranslationStore from "../../stores/TranslationStore.js";
import useLoadTranslations from "../../hooks/useLoadTranslations.js";

import { toast } from "react-toastify";
import { Col, Row, OverlayTrigger, Tooltip, Card } from "react-bootstrap";

const CreateForm = React.memo(
  ({
    handleInputChange,
    submitHandler,
    data,
    skusArray,
    handleFileUpload,
    handleValidateSkus,
    validationStatus,
    isEditig,
    noVisualsFlag,
    t,
  }) => {
    const [showPassword, setShowPassword] = useState(false);
    const excelTemplateFile = "/landing_page_creation_file.xlsx";

    return (
      <Card className="m-3">
           <div className="card-header display-6 ">Create Landing Page</div>
        <div className="card-body">
        {/* {isEditig ? (
          <CategoryDivider category="Edit landing page" />
        ) : (
          <CategoryDivider category="Create Landing page" />
        )} */}

        <form onSubmit={submitHandler}>
          <CategoryDivider category="1. Use the template file (optional)" />

          <Card className=" m-3 bg-light d-flex mb-5">
          <div className="card-header h5">Upload Template File</div>
          <div className="card-body">
            <div className="form-group ">
              <label htmlFor="fileUpload ">
                <div className="">
                  Upload Excel File to fill the fields automaticaly || Put{" "}
                  <strong>SKU's in column B</strong> and the{" "}
                  <strong>prices in column Q</strong> (
                  <span>
                    <a
                      href={excelTemplateFile}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {" "}
                      DOWNLOAD template file{" "}
                    </a>
                  </span>
                  )
                </div>
              </label>
              <input
                type="file"
                className="form-control"
                id="fileUpload"
                name="fileUpload"
                accept=".xlsx, .xls"
                onChange={handleFileUpload}
              />
            </div>
            </div>
          </Card>

          <CategoryDivider category="2. Enter Landing Page Details" />

          <Card className=" m-3 bg-light">
            <div className="form-group">
            <div className="card-header h5">Project Name</div>
            <div className="card-body">
              <span className="text-muted">{t("project_name_info")}</span>
              <input
                type="text"
                className="form-control"
                id="projectName"
                name="project_name"
                value={data.project_name || "Commercial Offer"}
                placeholder="Enter project name"
                onChange={handleInputChange}
                required
              />
            </div>
            </div>
          </Card>

          <Card className=" m-3 bg-light">
          <div className="card-header h5">Client data</div>
          <div className="card-body">
            <div className="form-group ">
              <label htmlFor="clientName" className="fw-bold">
                Company (shown in the header)
              </label>
              <input
                type="text"
                className="form-control"
                id="clientCompanyName"
                name="client_name"
                value={data.client_name || ""}
                placeholder="Enter client name"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientRepresentantName" className="fw-bold">
                Representant name
              </label>
              <input
                type="text"
                className="form-control"
                id="clientRepresentantName"
                name="client_representant"
                value={data.client_representant || ""}
                placeholder="Enter client representant name"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientRepresentantEmail" className="fw-bold">
                Representant email
              </label>
              <input
                type="email"
                className="form-control"
                id="clientRepresentantEmail"
                name="client_email"
                value={data.client_email || ""}
                placeholder="Enter representant email"
                onChange={handleInputChange}
              />
            </div>
            <div className="form-group">
              <label htmlFor="clientRepresentantPhone" className="fw-bold">
                Representant phone
              </label>
              <input
                type="text"
                className="form-control"
                id="clientRepresentantPhone"
                name="client_phone"
                value={data.client_phone || ""}
                placeholder="Enter representant phone"
                onChange={handleInputChange}
              />
            </div>
            </div>
          </Card>

          <Card className=" m-3 bg-light">
          <div className="card-header h5">Products list</div>
          <div className="card-body">
            <span className="text-muted">{t("landing_page_sku_info")}</span>
            <div className="form-group">
              <div className="d-flex">
                <textarea
                  className="form-control input-sku"
                  id="sku"
                  name="skus"
                  value={
                    data.skus
                      .map((item) =>
                        item.product_price
                          ? `${item.sku}(${item.product_price})`
                          : item.sku
                      )
                      .join("\n") || ""
                  }
                  placeholder="Enter sku separated by the new line. Custom price can be added in brackets. Example: RAN3066(10.99)"
                  onChange={handleInputChange}
                  required
                />
                <textarea
                  className={`form-control validation-status ${
                    validationStatus === "valid" ? "valid-skus" : "invalid-skus"
                  } ${noVisualsFlag === true ? "no-visuals" : ""}`}
                  value={
                    data.skus
                      .map((item) => {
                        if (item.validationStatus === "noData") {
                          return `${item.sku}: No Data`;
                        } else if (item.validationStatus === "noVisual") {
                          return `${item.sku}: No Visual`;
                        } else if (item.validationStatus === "valid") {
                          return `${item.sku}: Valid`;
                        } else {
                          return item.sku;
                        }
                      })
                      .join("\n") || ""
                  }
                  readOnly
                />
              </div>
            </div>

            <div className="m-3">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleValidateSkus}
              >
                Validate SKUs
              </button>
            </div>
            </div>
          </Card>

          <Card className="m-3 bg-light">
          <div className="card-header h5">Price settings</div>
          <div className="card-body">
            <span className="text-muted">{t("landing_page_price_info")}</span>
            <div className="container-fluid align-items-center mt-3">
              <div className="row">
                <div className="col-9 d-flex align-items-center">
                  <span className="me-2 fw-bold">Price text:</span>
                  <input
                    type="text"
                    className="form-control"
                    name="price_type"
                    value={data.price_type || ""}
                    onChange={handleInputChange}
                    placeholder="Text that will be shown before the product price value (default is 'Price')"
                  />
                  <span className="me-2 ms-5 fw-bold">Currency:</span>
                  <select
                    id="currency"
                    name="currency"
                    className="form-select form-currency"
                    value={data.currency || ""}
                    onChange={handleInputChange}
                  >
                    <option value="€">€ - Euro</option>
                    <option value="$">$ - USD</option>
                    <option value="£">£ - GBP</option>
                    {/* Dodaj inne języki */}
                  </select>
                </div>
              </div>
            </div>
            </div>
          </Card>

          <Card className="m-3 bg-light">
          <div className="card-header h5">Default language</div>
          <div className="card-body">
            <span className="text-muted">{t("landing_page_lang_info")}</span>
            <div className="form-group fw-bold">
              <select
                className="form-control"
                id="language"
                name="lang"
                value={data.lang || ""}
                onChange={handleInputChange}
              >
                <option value="en">English</option>
                <option value="fr">French</option>
                <option value="it">Italian</option>
                <option value="es">Spanish</option>
              </select>
            </div>
            </div>
          </Card>

          <Card className=" m-3 bg-light">
          <div className="card-header h5">Offer and expiration date</div>
          <div className="card-body">
            <span className="text-muted mb-3">
              {t("landing_page_date_info")}
            </span>
            <Row>
              <Col className="form-group fw-bold">
                <label htmlFor="offerDate">Offer Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="offerDate"
                  name="offer_date"
                  value={data.offer_date || ""}
                  onChange={handleInputChange}
                />
              </Col>
              <Col className="form-group fw-bold">
                <label htmlFor="expDate">Expiration Date</label>
                <input
                  type="date"
                  className="form-control"
                  id="expDate"
                  name="exp_date"
                  value={data.exp_date || ""}
                  onChange={handleInputChange}
                />
              </Col>
            </Row>
            </div>
          </Card>

          <Card className=" m-3 bg-light">
            <div className="card-header h5">CPassword Setting</div>
          <div className="card-body">
            <span className="text-muted">{t("landing_page_pass_info")}</span>
            <Row className="form-group fw-bold d-flex flex-column align-items-left justify-content-center w-100 mt-3">
              <Col
                md={8}
                s={8}
                className="d-flex align-items-center justify-content-start flex-row gap-3"
              >
                <label htmlFor="hasPassword" className="d-block">
                  {t("password_protected_?")}
                </label>
                <div className="form-check form-switch">
                  <input
                    type="checkbox"
                    className="form-check-input"
                    id="hasPassword"
                    name="has_password"
                    checked={data.has_password || false} // Obsługa wartości
                    onChange={handleInputChange}
                  />
                </div>
              </Col>
              <Col md={8} s={8} className="d-flex flex-column gap-3">
                <div className="input-group">
                  <input
                    type={showPassword ? "text" : "password"} // Dynamiczny typ pola
                    className="form-control"
                    id="password"
                    name="password"
                    value={data.password || ""}
                    onChange={handleInputChange}
                  
                  />
                  <button
                    type="button"
                    className="btn btn-outline-secondary btn-sm"
                    onClick={() => setShowPassword((prev) => !prev)} // Toggling widoczności hasła
                  >
                    {showPassword ? t("hide") : t("show")}
                  </button>
                </div>
              </Col>
            </Row>
            </div>
          </Card>

            <div className="m-3">      
          <button type="submit" className="btn btn-primary w-100">
            Submit
          </button>
          </div>
            {data.page_key && (
              <h4 className="px-3 mb-3">
                Offer link:{" "}
                <a href={`/offer/${data.page_key}`} target="_blank">
                  {proxy}/offer/{data.page_key}
                </a>
              </h4>
            )}


          
        </form>
      </div>
      </Card>
    );
  }
);

const ReturnLink = React.memo(({ link }) => {
  return (
    <>
      <CategoryDivider category="Link for the landing page created" />
      <div
        className="my-5 align-items-center d-flex flex-column"
        style={{ textAlign: "center" }}
      >
        <div className="fs-2">
          <a href={link} target="_blank" rel="noopener noreferrer">
            {link}
          </a>
        </div>
        <button
          className="btn btn-primary my-5"
          onClick={() => window.location.reload()}
        >
          Create another landing page
        </button>
      </div>
    </>
  );
});

const LandingPageForm = () => {
  const { page_key } = useParams();
  const [pageKey, setPageKey] = useState("");
  const [link, setLink] = useState("");
  const user = localStorage.getItem("user");
  const [isEditing, setIsEditing] = useState(false);
  const [landingPageData, setLandingPageData] = useState({});
  const [landingPageOwner, setLandingPageOwner] = useState("");
  const [skusArray, setSkusArray] = useState([]);

  const [noVisualsFlag, setNoVisualsFlag] = useState(false);

  const { t } = useTranslation("landing_page");
  // Ładowanie tłumaczeń
  const { userLang, setClientLang } = useTranslationStore();
  useLoadTranslations(userLang, "landing_page");
  const isReady = useTranslationStore((state) => state.isReady);

  const { roles, logout } = useContext(AuthContext);
  const hasAccess = (role) => roles.includes(role);

  const [data, setData] = useState({
    client_name: "",
    client_representant: "",
    client_email: "",
    client_phone: "",
    project_name: "",
    skus: [],
    price_type: "Price",
    lang: "",
    offer_date: "",
    exp_date: "",
    currency: "€",
    has_password: false,
    password: "",
  });

  // foramtownie listy SKU
  const formatSkus = (input) => {
    return input
      .split(/[,\s;]+/) // Podziel na podstawie spacji, przecinka, średnika lub ich kombinacji
      .filter(Boolean) // Usuń puste elementy
      .join("\n"); // Połącz z powrotem używając nowej linii
  };

  useEffect(() => {
    const fetchLandingPageData = async () => {
      if (page_key) {
        try {
          const response = await axiosInstance.get(
            `${proxy}/api/landing-page/get/${page_key}`,
            {}
          );
          const landingPageData = response.data;
          setLandingPageData(landingPageData);

          if (landingPageData.length > 0) {
            const data = landingPageData[0];
            setLandingPageOwner(data.user);
            const parsedSkus = JSON.parse(data.skus); // Parsowanie danych skus
            const offerDate = format(new Date(data.offer_date), "yyyy-MM-dd");
            const expDate = format(new Date(data.exp_date), "yyyy-MM-dd");
            setData({
              ...data,
              skus: parsedSkus,
              offer_date: offerDate,
              exp_date: expDate,
            });

            // converting skus object to an array with only skus
            const skusArray = parsedSkus.map((item) => item.sku);
            setSkusArray(skusArray);
            setIsEditing(true);
          }
        } catch (error) {
          toast.error("Error fetching landing page data");
          // console.log(error);
        }
      }
    };

    fetchLandingPageData();
  }, [page_key]);

  const [validationStatus, setValidationStatus] = useState("");

  const handleInputChange = useCallback((e) => {
    const { name, value, type, checked } = e.target; // Dodajemy 'type' i 'checked'

    if (name === "skus") {
      const skusArray = value.split("\n").map((item) => {
        const match = item.match(/^(.*?)(?:\((.*?)\))?$/);
        return {
          sku: match[1].trim(),
          product_price: match[2] ? match[2].trim() : "",
        };
      });
      setData((prevData) => ({
        ...prevData,
        [name]: skusArray,
      }));
    } else {
      setData((prevData) => ({
        ...prevData,
        [name]: type === "checkbox" ? checked : value, // Obsługa checkboxów
      }));
    }
  }, []);

  const convertDateFormat = (dateStr) => {
    const date = new Date(dateStr);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Miesiące są indeksowane od 0
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };

  const handleFileUpload = useCallback((e) => {
    const file = e.target.files[0];
    if (file) {
      readXlsxFile(file)
        .then((rows) => {
          // console.log("rows", rows);
          const updatedData = {
            client_name: rows[4][1],
            // client_representant: rows[2][2],
            // client_email: rows[2][3],
            // client_phone: rows[2][4],
            // project_name: rows[2][0],
            skus: rows
              .slice(14)
              .map((row) => ({
                sku: row[1],
                product_price: row[16],
              }))
              .filter((item) => item.sku),
            lang: "",
            offer_date: convertDateFormat(rows[6][1]),
            exp_date: convertDateFormat(rows[7][1]),
          };
          setData((prevData) => ({
            ...prevData, // zachowuje istniejące dane
            ...updatedData, // nadpisuje lub dodaje nowe pola
            skus: [...(prevData.skus || []), ...updatedData.skus], // łączy listy SKU
          }));
        })
        .catch((error) => {
          console.error("Error reading Excel file:", error);
        });
    }
  }, []);

  const handleValidateSkus = useCallback(async () => {
    // Formatowanie SKU bez utraty danych o cenach
    const skusArray = data.skus.map((item) => {
      // Zapisujemy SKU oraz cenę (jeśli istnieje)
      // console.log("item.product_price", item.product_price);
      // convert to string
      const price = item.product_price ? item.product_price.toString() : "";
      return { 
        sku: item.sku.trim(),
        product_price: price.trim(),
      };
    });
  
    setData((prevData) => ({
      ...prevData,
      skus: skusArray,
    }));
  
    try {
      // Walidacja SKU na backendzie
      const response = await axiosInstance.post(
        `${proxy}/api/products/validate-skus`,
        { skus: skusArray.map((item) => item.sku) }, // Tylko SKU wysyłamy do walidacji
        {}
      );
  
      const { validSkus, skusNoData, skusNoVisual } = response.data;
  
      // Aktualizacja danych SKU na podstawie wyników walidacji
      const updatedSkus = skusArray.map((item) => {
        if (skusNoData.includes(item.sku)) {
          return { ...item, validationStatus: "noData" };
        } else if (skusNoVisual.includes(item.sku)) {
          setNoVisualsFlag(true);
          return { ...item, validationStatus: "noVisual" };
        } else {
          return { ...item, validationStatus: "valid" };
        }
      });
  
      // Sprawdzanie statusu walidacji
      const allValid = updatedSkus.every(
        (item) =>
          item.validationStatus === "valid" ||
          item.validationStatus === "noVisual"
      );
      setValidationStatus(allValid ? "valid" : "invalid");
  
      // Zapis zaktualizowanych danych
      setData((prevData) => ({
        ...prevData,
        skus: updatedSkus,
      }));
  
      return allValid; // Zwróć wynik walidacji
    } catch (error) {
      toast.error("Error validating SKUs");
      console.error("Error validating SKUs:", error);
      return false; // Zwróć wynik walidacji
    }
  }, [data.skus]);
  

  const createLink = useCallback((key) => {
    setLink(process.env.REACT_APP_FRONTEND_URL + "/offer/" + key);
  }, []);

  const submitHandler = useCallback(
    async (e) => {
      e.preventDefault();

      try {
        let response;
        if (isEditing) {
          response = await axiosInstance.post(
            `${proxy}/api/landing-page/update`,
            {
              data,
              user,
            },
            {}
          );
        } else {
          response = await axiosInstance.post(
            `${proxy}/api/landing-page/add`,
            {
              data,
              user,
            },
            {}
          );
        }

        createLink(response.data);
        if (isEditing) {
          toast.success("Landing page updated successfully");
        } else {
          setPageKey(response.data);
        }
      } catch (error) {
        toast.error("Error generating content");
        console.error("Error generating content:", error);
      }
    },
    [data, user, createLink, isEditing]
  );

  const submitHandlerWithValidation = useCallback(
    async (e) => {
      e.preventDefault();
      const isValid = await handleValidateSkus();
      if (isValid) {
        await submitHandler(e);
      } else {
        alert("Please validate all SKUs before submitting.");
      }
    },
    [handleValidateSkus, submitHandler]
  );

  if (
    !page_key ||
    (landingPageOwner !== user &&
      page_key !== "create" &&
      !(page_key && hasAccess("superuser")))
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 p-5 m-5">
        <h3>You are not authorized to edit this landing page </h3>
      </div>
    );
  }

  if (
    page_key &&
    Object.keys(landingPageData).length === 0 &&
    page_key !== "create"
  ) {
    return (
      <div className="d-flex justify-content-center align-items-center h-100 p-5 m-5">
        <h3>Cannot find landing page with key: {page_key}</h3>
      </div>
    );
  }

  return (
    <>
      {!pageKey ? (
        <CreateForm
          handleInputChange={handleInputChange}
          submitHandler={submitHandlerWithValidation}
          data={data}
          skusArray={skusArray}
          handleFileUpload={handleFileUpload}
          handleValidateSkus={handleValidateSkus}
          validationStatus={validationStatus}
          isEditig={isEditing}
          noVisualsFlag={noVisualsFlag}
          t={t}
        />
      ) : (
        <ReturnLink link={link} />
      )}
    </>
  );
};

export default LandingPageForm;
