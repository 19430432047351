import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPageLayout from "../layouts/LandingPageLayout";
import LandingPage from "../pages/public/LandingPage";
import Contact from "../pages/public/ContactPage";

const RetailRoutes = () => {
    const manualPageKey = "pf9zvdg";
  return (
    <LandingPageLayout>
      <Routes>
           <Route path="/contact" element={<LandingPage manualPageKey={manualPageKey} isContact={true}  sufix="retail" />} />
           <Route path="/*" element={<LandingPage manualPageKey={manualPageKey} isContact={true}  sufix="retail" /> } />
      </Routes>
    </LandingPageLayout>
  );
};

export default RetailRoutes;
