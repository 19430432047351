// src/pages/public/LandingPage.js

import React, { useEffect, useState } from "react";
import { useParams, useLocation, useNavigate } from "react-router-dom"; // Dodanie useNavigate
import {Col, Row } from "react-bootstrap";

// importing components
import ContactPage from "./ContactPage.js";
import ProductModal from "../../components/landingPages/ProductModal.js"; // Import ProductModal
import SelectedProductsDropdown from "../../components/landingPages/SelectedProductsDropdown.js"; // Import SelectedProductsDropdown
import LandingPageNavigation from "../../components/navigation/LandingPageNavigation";
import DisplayCards from "../../components/landingPages/DisplayCards.js";
import SearchAndFilter from "../../components/landingPages/SearchAndFilter";
import LandingPageHeader from "../../components/landingPages/LandingPageHeader.js";
import PasswordInput from "../../components/utilities/passwordForm.js";

// css
import "./LandingPage.css";

// hooks
import useLandingPageData from "../../hooks/useLandingPageData.js";
import { isOfferExpired } from "../../services/landingPageService.js";

// translations
import { useTranslation } from "react-i18next";
import useTranslationStore from "../../stores/TranslationStore.js";
import useLoadTranslations from "../../hooks/useLoadTranslations.js";

// context
import { useLandingPageContext } from "../../context/LandingPageContext.js";

// tools
import { normalizeString } from "../../utils/dataHelpers.js";



const LandingPage = ({ manualPageKey = null, isContactFlag, sufix}) => {


  // language setup
  const { clientLang, setClientLang } = useTranslationStore();
  const { t } = useTranslation("landing_page");

  // Ładowanie tłumaczeń
  useLoadTranslations(clientLang, "landing_page");

  const isReady = useTranslationStore((state) => state.isReady);

  // navigation
  const location = useLocation();
  const navigate = useNavigate(); // Deklaracja useNavigate
  const [isContact, setIsContact] = useState(isContactFlag || false);
  const [highlightTrigger, setHighlightTrigger] = useState(false);



  // data
  const { landingPageData, setLandingPageData } = useLandingPageContext();
  const { landingPageSkuList, setLandingPageSkuList } = useLandingPageContext();
  const [skuList, setSkuList] = useState([]);
  const [selectedSkus, setSelectedSkus] = useState(() => {
    const storedSkus = sessionStorage.getItem("selectedSkus");
    return storedSkus ? JSON.parse(storedSkus) : [];
  });
  const [groupedProducts, setGroupedProducts] = useState({});
  const [passwordProtectedFlag, setPasswordProtectedFlag] = useState(false);


  // pageKey
  const { page_key_params } = useParams();
  const [isManualOffer, setIsManualOffer] = useState(false);

  const page_key = manualPageKey || page_key_params;
  const [loggedIn, setLoggedIn] = useState(false);

  // Filters
  const [filteredProducts, setFilteredProducts] = useState([]);

   // fetching data
   const { data, isLoading, isError } = useLandingPageData(page_key);

   // Modal data
   const [showModal, setShowModal] = useState(false);
   const [selectedProduct, setSelectedProduct] = useState(null);


  const handleFilterUpdate = (filtered) => {
    setFilteredProducts(filtered);
  };

  useEffect(() => {
    if (landingPageData && landingPageData.products) {
      setFilteredProducts(landingPageData.products); // Domyślna lista to wszystkie produkty
    }
  }, [landingPageData]);



  useEffect(() => {
    if (manualPageKey) {
      setIsManualOffer(true);
    }
  }, [manualPageKey]);

  
  // settind data to the states
  useEffect(() => {
    if (data) {
      setLandingPageData(data.data);
      setLandingPageSkuList(data.skus);
      setSkuList(data.skus);

  // check if offer is password protected
      if(data.data.landingPageData.has_password) {
        setPasswordProtectedFlag(true);
      }
      else {
        const isExpired = isOfferExpired(data.data.landingPageData.creation_date, data.data.landingPageData.exp_date, 14);
        // console.log("isExpired", isExpired);
        setPasswordProtectedFlag(isExpired);
      }

      const { language } = data;
      // Jeśli użytkownik nie zmienił języka ręcznie, ustaw język z backendu
      const storedLang = localStorage.getItem("clientLang");
      if (!storedLang) {
        setClientLang(language);
      }
    }
  }, [data, setLandingPageData, setLandingPageSkuList]);


  // grouping products by category
  useEffect(() => {
    if (!landingPageData || !landingPageData.products) return;
    const groupProducts = landingPageData.products.reduce((acc, product) => {
      let category = normalizeString(product.business_category);
      let categoryTranslated = t(category);
      if (category === null || category === "" || category === "#n/a" || category === "undefined" || category === undefined)
        categoryTranslated = t("other");
      if (!acc[categoryTranslated]) {
        acc[categoryTranslated] = [];
      }
      acc[categoryTranslated].push(product);
      return acc;
    }, {});
    setGroupedProducts(groupProducts);
  }, [landingPageData, t]);


  // logowanie danych
  useEffect(() => {
    // console.log("groupedProducts", groupedProducts);
    // console.log("landingPageData", landingPageData);
  }, [groupedProducts]);


  // setting logging in state
  useEffect(() => {
    sessionStorage.getItem(`${page_key}_loggedIn`) === "true" ? setLoggedIn(true) : setLoggedIn(false);
  }, [page_key]);
  
  // handeling logging in
  useEffect(() => {
    sessionStorage.setItem(`${page_key}_loggedIn` , loggedIn);
    // console.log("loggedIn", loggedIn);
  }, [loggedIn, setLoggedIn]);


  // Trigger highlight
  const triggerHighlight = () => {
    setHighlightTrigger((prev) => !prev);
  };

  // Funkcja otwierająca modal z danymi produktu
  const handleProductClick = (product) => {
    const productData = findProductBySku(product.sku);
    setSelectedProduct(productData);
    setShowModal(true);
  };

  // Funkcja zamykająca modal
  const handleCloseModal = () => {
    setShowModal(false);
    setSelectedProduct(null);
  };

  // Funkcja do obsługi checkboxa
  const handleCheckboxChange = (sku) => {
    setSelectedSkus((prevSelectedSkus) => {
      triggerHighlight();
      let newSelectedSkus;
      if (prevSelectedSkus.includes(sku)) {
        newSelectedSkus = prevSelectedSkus.filter((item) => item !== sku);
      } else {
        newSelectedSkus = [...prevSelectedSkus, sku];
      }
      sessionStorage.setItem("selectedSkus", JSON.stringify(newSelectedSkus));
      return newSelectedSkus;
    });
  };

  //funkcja do zaznaczania wszystkich produktów
  const handleSelectAll = () => {
    const allSkus = landingPageSkuList.map((sku) => sku.sku);
    setSelectedSkus(allSkus);
    triggerHighlight();
    sessionStorage.setItem("selectedSkus", JSON.stringify(allSkus));
  };

  //funkcja do odznaczania wszystkich produktów
  const handleDeselectAll = () => {
    setSelectedSkus([]);
    triggerHighlight();
    sessionStorage.setItem("selectedSkus", JSON.stringify([]));
  };

  // Funkcja do przechodzenia do strony kontaktowej z wybranymi SKU
  const handleAskForProducts = () => {

    let url;
    if (isManualOffer) {
      url = `/${sufix}/contact`;
    } else {
      url = `/offer/${page_key}/contact`;
    }

    navigate(url, { state: { selectedSkus } });
  };

  // scroll to top
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // settinc isContact state
  useEffect(() => {
    if (location.pathname.includes("/contact")) {
      setIsContact(true);
    } else {
      setIsContact(false);
    }
  }, [location]);

  // Funkcja do wyszukiwania obiektu po "sku"
  function findProductBySku(sku) {
    if (!landingPageData || !Array.isArray(landingPageData.productsTableData)) {
      return null;
    }
    return landingPageData.productsTableData.find(
      (product) => product.sku === sku
    );
  }

  // show loading if data is not ready
  if (!data || !isReady || isLoading) {
    return <div>Loading...</div>;
  }

  if (!landingPageData || isError || landingPageData === "404") {
    return (
      <div className="error-404-container">
        <div className="error-404-text">{t["loading..."]}</div>
      </div>
    );
  }

if(passwordProtectedFlag && loggedIn !== true) {
  return (
    <div className="error-404-container">
   <PasswordInput setLoggedIn={setLoggedIn} pageKey={page_key}/>
    </div>
  );
}

  return (
    <>
      <LandingPageNavigation pageKey={page_key} isManualOffer={isManualOffer} sufixValue={sufix}  />
      {isContact ? (
        <ContactPage pageKey={page_key} skus={selectedSkus} />
      ) : (<>
        <div className="landing-page-container" style={{ minHeight: '92vh' }}>
          <LandingPageHeader landingPageData={landingPageData} />
            <Row className="w-100">
            <Col xs={12} md={3} lg={2}>      
            <div className="d-flex w-100 align-content-center justify-content-center m-3 mt-5 flex-wrap py-3 select-products">
                <span className="product-select-header">{t('select_products')}</span>
                <button
                  className="btn btn-primary btn-sm shadow mx-2 mt-2" 
                  onClick={handleSelectAll}
                >
                  {t("select_all")}
                </button>
                <button
                  className="btn btn-secondary btn-sm shadow mx-2 mt-2"
                  onClick={handleDeselectAll}
                >
                  {t("deselect_all")}
                </button>
            </div> 
                  <SearchAndFilter
                products={landingPageData.products || []}
                onFilter={handleFilterUpdate}
                normalizeString={normalizeString}
                t={t}
                />
             
            </Col>
            <Col xs={12} md={9} lg={10}>
                
            <DisplayCards
              landingPageData={landingPageData}
              groupedProducts={groupedProducts}
              page_key={page_key}
              handleSelectAll={handleSelectAll}
              handleDeselectAll={handleDeselectAll}
              t={t}
              handleProductClick={handleProductClick}
              handleCheckboxChange={handleCheckboxChange}
              selectedSkus={selectedSkus}
              filteredProducts={filteredProducts} // Użycie przefiltrowanej listy
              normalizeString={normalizeString}
              clientLang={clientLang}
            />
              </Col>
            </Row>

          </div>
        </>
      )}
      {showModal ? (
        <ProductModal
          showModal={showModal}
          handleCloseModal={handleCloseModal}
          product={selectedProduct}
          landingPageData={landingPageData}
          skuList={skuList}
          handleCheckboxChange={handleCheckboxChange}
          handleAskForProducts={handleAskForProducts}
        />
      ) : null}
      {!isContact && (
        <SelectedProductsDropdown
          selectedSkus={selectedSkus}
          handleAskForProducts={handleAskForProducts}
          triggerHighlight={highlightTrigger}
          nameSpace="landing_page"
        />
      )}
    </>
  );
};

export default LandingPage;
