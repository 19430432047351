import React, { useState, useEffect, useContext } from 'react';
import Select from 'react-select';
import { format as dateFnsFormat } from 'date-fns';
import './ExcelGenerationForm.css';
import proxy from '../setup/proxy';
import axiosInstance from "../setup/axiosInstance.js";
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
// import 'bootstrap/dist/css/bootstrap.min.css';

// services
import ProductDataService from '../services/ProductDataService';

// Import AuthContext
import { AuthContext } from '../context/AuthContext';

const DataButtons = ({ handleAddColumns }) => {
    const dataCategories = {
      "IDENTIFICATION DATA": ["ASIN", "EAN"],
      "VISUAL": ["VISUAL", "PACKAGING IMAGE"],
      "DESCRIPTIONS": [
        "BUSINESS DESCRIPTION", "BUSINESS DESCRIPTION - FR", "BUSINESS DESCRIPTION - ES", "BUSINESS DESCRIPTION - IT"
      ],
      "PHYSICAL DATA": [
        "MATERIAL", "MATERIAL TAG", "COLOR TAG", "SIZE TAG", "PACKAGING", "QUANTITY IN PACK",
        "PRODUCT'S LENGTH CM LONGUEUR", "PRODUCT'S WIDTH CM LARGEUR", "PRODUCT'S HEIGHT CM HAUTEUR",
        "PRODUCT NET WEIGHT WITHOUT PACKAGING"
      ],
      "PACKAGING DATA": [
        "PACKAGING HEIGHT CM", "PACKAGING WIDTH", "PACKAGING DEPTH", "PRODUCT GROSS WEIGHT WITH PACKAGING",
        "CARTON'S LENGTH CM", "CARTON'S WIDTH CM", "CARTON'S HEIGHT CM", "CBM", "PCS/20' 28cbm2",
        "PCS/40' 56cbm2", "PCS/40HQ 66cbm"
      ],
      "ORIGIN DATA": ["MOQ", "COUNTRY OF ORIGIN", "PORT", "TARIFF CODE", "Supplier 1", "Supplier 2"],
      "CATEGORIZATION DATA": [
        "BRAND", "MAIN CATEGORY", "BUSINESS CATEGORY", "COMMERCIAL Category", "COLLECTION TAG", "NAME"
      ],
      "PRICING DATA": [
        "Most recent price update", "Price supplier 1 USD", "Price supplier 2", "OSL Interco Price USD",
        "Direct factory FOB Price USD", "Duty rate %", "UNITARY FREIGHT COST USD", "LANDED COST AT PORT USD",
        "DUTY VALUE USD", "LANDED COST AFTER DUTY eur", "UNITARY TRACTION COST eur", "LANDED COST AFTER TRACTION eur",
        "RACKING WAREHOUSE", "LANDED COST EUROPE EUR", "Interco Price EUR", "RETAIL PRICE HT EUR",
        "RECOMMENDED RETAIL PRICE TTC EUR", "REAL STORE RETAIL PRICE FR EUR", "B2c Gross Margin EUR",
        "DDP WAREHOUSE b2b CIE FR/IT/ES/AMAZON EUR", "DIRECT TO STORE b2b CIE FR/IT/ES/AMAZON FRANCO 495 Eur",
        "b2b COGS EUR", "b2b Commercial Margin EUR", "DDP UK mov gbp 700", "DDP BENELUX mov 295 eur",
        "EXW EXPORT DIRECT RETAILER No Mov EUR", "EXW DISTRIBUTOR RESELLER No Mov EUR",
        "PRIX B2B HT REVENDEUR SITE B2B Franco 495 eur", "PRIX B2B HT UTILISATEUR Franco 495 eur",
        "TARIF BRUT FRANCE 2024", "TARIF NET FACTURÉ MAG", "TARIF NET FACTURÉ ENTREPÔT CENTRALES AND NONAL",
        "MARGE BRUTE AVANT COUT LIVRAISON ET COM", "TARIF BRUT ALIMENTAIRE FRANCHISE 2024",
        "TARIF NET FACTURÉ MAG ALIMENTAIRE FRANCHISE 2024", "MARGE BRUTE AVANT COUT LIV ET COM"
      ]
    };
  
    const renderTooltip = (items) => (
      <Tooltip style={{ maxWidth: '400px', whiteSpace: 'normal', minWidth:'300px'}}>
        <ul className="m-0 p-0" style={{ fontSize: '11px' }}>
          {items.map((item, index) => (
            <li key={index}>• {item}</li>
          ))}
        </ul>
      </Tooltip>
    );
  
    return (
      <div>
        {Object.keys(dataCategories).map((category, index) => (
          <OverlayTrigger
            key={index}
            placement="bottom"
            delay={{ show: 150, hide: 400 }}
            overlay={renderTooltip(dataCategories[category])}
          >
            <Button className="btn btn-sm btn-primary m-1" onClick={() => handleAddColumns(dataCategories[category])}>
              {category}
            </Button>
          </OverlayTrigger>
        ))}
      </div>
    );
  };


  const DataForm = () => {
    const [skuInput, setSkuInput] = useState('');
    const [selectedColumns, setSelectedColumns] = useState([]);
    const [lang, setLang] = useState('');
    const [createQuotation, setCreateQuotation] = useState(false);
    const [createInternalQuotation, setCreateInternalQuotation] = useState(false);
    const [columnsOptions, setColumnsOptions] = useState([]);
    const [isSelectDisabled, setIsSelectDisabled] = useState(false);
    const [businessCategoryList, setBusinessCategoryList] = useState([]);
    const [commercialCategoryList, setCommercialCategoryList] = useState([]);

    const { roles } = useContext(AuthContext);
    const hasAccess = (role) => roles.includes(role);

    // set the 


    useEffect(() => {
        const fetchData = async () => {
          try {
            const columns = await ProductDataService.getColumns(); // get columns
            // console.log('Columns:', columns);
            const filteredOptions = ProductDataService.parseColumsToOptions(columns); // parse columns to options
            setColumnsOptions(filteredOptions);

            // fetchBusinessCategories
            const businessCategories = await ProductDataService.fetchBusinessCategories();
            setBusinessCategoryList(businessCategories);

            // fetchCommercialCategories
            const commercialCategories = await ProductDataService.fetchCommercialCategories();
            setCommercialCategoryList(commercialCategories);
          } catch (error) {
            console.error('Error fetching column data:', error);
          }
        };
        fetchData();
      }, []);

    useEffect(() => {
        setIsSelectDisabled(createQuotation || createInternalQuotation);
    }, [createQuotation, createInternalQuotation]);

    const handleCheckboxChange = (type) => {
        if (type === 'quotation') {
            setCreateQuotation(prevState => !prevState);
            setCreateInternalQuotation(false);
        } else if (type === 'internalQuotation') {
            setCreateInternalQuotation(prevState => !prevState);
            setCreateQuotation(false);
        }
    };

    
    const handleFormSubmit = async (event) => {
        event.preventDefault();
        const product = skuInput.split(/[\s,]+/).join(',');
        const columns = selectedColumns.map(option => option.value);
        const formData = {
            product,
            columns,
            quotation: createQuotation,
            internalQuotation: createInternalQuotation,
            lang
        };
        // console.log('formData:', formData);
        let fetchUrl = `${proxy}/api/products/get-data-xlsx`;
        let FileName = encodeURIComponent(`Products_data-${dateFnsFormat(new Date(), "yyyy-MM-dd")}`);

        if (createQuotation) {
            fetchUrl = `${proxy}/api/products/create-quotation-file`;
            FileName = encodeURIComponent(`Quotation-${dateFnsFormat(new Date(), "yyyy-MM-dd")}`);
        } else if (createInternalQuotation) {
            fetchUrl = `${proxy}/api/products/create-quotation-file`;
            FileName = encodeURIComponent(`Internal_Quotation-${dateFnsFormat(new Date(), "yyyy-MM-dd")}`);
        }

        try {
            const response = await axiosInstance.post(fetchUrl, formData, {
              responseType: 'blob',
              headers: {
                'Content-Type': 'application/json',
              },
            });
        
            const blob = new Blob([response.data], { type: response.headers['content-type'] });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = `${FileName}.xlsx`;
            document.body.appendChild(a); // Dodaj element do dokumentu
            a.click();
            document.body.removeChild(a); // Usuń element po kliknięciu
        } catch (error) {
            console.error('Error:', error);
        }
    };

    const handleSelectChange = (selectedOptions) => {

        setSelectedColumns(selectedOptions);
    };

    const handleAddSkusFromCategory = async (e) => {
        const category = e.target.value;
        const column  = e.target.selectedOptions[0].getAttribute('data-column');
        try {
            const response = await axiosInstance.post(`${proxy}/api/products/skus-for-column-value`, {"column": column, "value": category});
            const skus = response.data;
            // console.log('SKUs:', skus);
            
           const skuValues = skus.map(obj => obj.sku).join('\n');
           addSkusToInput(skuValues);
        } catch (error) {
            console.error('Error fetching SKUs:', error);
        }
    }

    const addSkusToInput = (skus) => {
        setSkuInput(skuValues => {
          // Jeśli aktualna zawartość nie jest pusta, dodaj nową linię przed nowymi wartościami
          const prefix = skuValues ? '\n' : '';
          return skuValues + prefix + skus;
        });
    };

    const handleAddColumns = (columns) => {
        const updatedColumns = [...selectedColumns];
      
        columns.forEach(col => {
          const option = columnsOptions.find(option => option.label === col);
          if (option && !updatedColumns.some(selected => selected.value === option.value)) {
            updatedColumns.push(option);
          }
        });
      
        setSelectedColumns(updatedColumns);
      };

    return (
        <div className="container">
            <form onSubmit={handleFormSubmit} className="form--container"> 
                <div className="row my-3">
                    <div className="col-12 col-md-3 px-2 mb-3 mb-md-0">
                        <div className="form--card h-100">
                            <div className="form--row"><span><strong>Put SKUs </strong>(required):</span></div>
                            <div className="form--row"> 
                                <textarea  
                                    className="form--textarea"
                                    value={skuInput}
                                    onChange={(e) => setSkuInput(e.target.value)}
                                    required
                                />
                            </div>
                            <div className="mt-3 mb-2">
                                <strong>Or add SKUS from category:</strong>
                            </div>
                            <div className="form--row"> 
                           
                            <select className="form-select form-select-s mb-3" aria-label=".form-select-lg example"  onChange={handleAddSkusFromCategory}>
                                <option>BUSINESS CATEGORY</option>
                                {
                                    businessCategoryList.map((category, index) => (
                                        <option key={index} value={category} data-column="business_category">
                                            {category}
                                        </option>
                                    ))
                                }
                            </select>
                            </div>
                            <div>
                            <select className="form-select form-select-s mb-3" aria-label=".form-select-lg example"  onChange={handleAddSkusFromCategory}>
                                <option>COMMERCIAL CATEGORY</option>
                                {
                                    commercialCategoryList.map((category, index) => (
                                        <option key={index} value={category} data-column="commercial_category">
                                            {category}
                                        </option>
                                    ))
                                }
                            </select>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-9 px-2">
                        
                        <div className="form--card h-100">
                            <div className="d-flex justify-content-center"><span><h4>Select options</h4></span></div>
                        <hr className="hr" />
                                <div><h5>1. Create custom Excel sheet</h5></div>
                        <hr className="hr" />
                        <div className="ps-4">
                            <div><span><strong>Select columns </strong>(SKU is automatically added to the excel file):</span></div>
                            <Select
                                options={columnsOptions}
                                isMulti
                                onChange={handleSelectChange}
                                value={selectedColumns}
                                isDisabled={isSelectDisabled} 
                            />
                            <div className="pt-3">
                                <strong>or add predefined columns:</strong>
                            </div>
                            <div>
                                <DataButtons handleAddColumns={handleAddColumns} />
                            </div>
                        </div>
                        <hr className="hr mt-5" />
                                <div><h5>2. Use offers template <small className="fw-light">(columns selected manualy are ignored)</small></h5></div>
                        <hr className="hr" />
                           <div className="ps-4">
                                <div className="row mb-2">
                                    <span><strong>Select for generate quotation:</strong></span>
                                </div>
                                <div className="row ps-2">
                                    <label className="form-check form-switch">
                                        <input
                                            className="form-check-input"
                                            type="checkbox"
                                            checked={createQuotation}
                                            onChange={() => handleCheckboxChange('quotation')}
                                        /> Customer Quotation
                                    </label>
                                    {hasAccess("internal_quotation") || hasAccess("superuser") ? (
                                        <label className="form-check form-switch">
                                            <input
                                                className="form-check-input"
                                                type="checkbox"
                                                checked={createInternalQuotation}
                                                onChange={() => handleCheckboxChange('internalQuotation')}
                                            /> Internal Quotation
                                        </label>
                                    ) : ""}
                                </div>
                            </div>
                          
                            <div className="my-3 ps-3">
                                <span style={{marginRight: "15px"}}><strong>Select Language:</strong> </span>
                                <select className="form-select form-select-sms mb-3" value={lang} onChange={(e) => setLang(e.target.value)}>
                                    <option value="">ENGLISH</option>
                                    <option value="fr">FRENCH</option>
                                    <option value="es">SPANISH</option>
                                    <option value="it">ITALIAN</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <button type="submit" className="btn btn-primary w-100">Generate excel</button>
                    </div>
                </div>
            </form>
        </div>
    );
};

export { DataForm };