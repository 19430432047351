import React, { useState, useEffect, useMemo } from "react";

const SearchAndFilter = ({ products, onFilter, normalizeString, t }) => {
  const [searchQuery, setSearchQuery] = useState("");
  const [activeFilters, setActiveFilters] = useState(() => {
    // Pobierz filtry z localStorage
    const storedFilters = JSON.parse(localStorage.getItem("activeFilters")) || {};
    // console.log("storedFilters", storedFilters);
    const categories = Array.from(
      new Set(products.map((product) => normalizeString(product.business_category)))
    );

    // Zainicjalizuj stan filtrów
    return categories.reduce((acc, category) => {
      acc[category] = storedFilters[category] ?? true;
      return acc;
    }, {});
  });

  const [isAccordionOpen, setIsAccordionOpen] = useState(true);

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const toggleFilter = (category) => {
    // console.log("category form filter", category);
    setActiveFilters((prevFilters) => {
      const updatedFilters = {
        ...prevFilters,
        [category]: !prevFilters[category], // Przełącz stan filtra
      };

      // Zapisz do localStorage
      localStorage.setItem("activeFilters", JSON.stringify(updatedFilters));

      return updatedFilters;
    });
  };

  const selectAllFilters = () => {
    const updatedFilters = Object.keys(activeFilters).reduce((acc, category) => {
      acc[category] = true;
      return acc;
    }, {});
    setActiveFilters(updatedFilters);
    localStorage.setItem("activeFilters", JSON.stringify(updatedFilters));
  };

  const deselectAllFilters = () => {
    const updatedFilters = Object.keys(activeFilters).reduce((acc, category) => {
      acc[category] = false;
      return acc;
    }, {});
    setActiveFilters(updatedFilters);
    localStorage.setItem("activeFilters", JSON.stringify(updatedFilters));
  };

  const filteredProducts = useMemo(() => {
    return products.filter((product) => {
      const normalizedQuery = searchQuery.toLowerCase();

      // Normalizacja kategorii
      const normalizedCategory = normalizeString(product.business_category) || "other";

      const matchesSearch =
        product.sku.toLowerCase().includes(normalizedQuery) ||
        product.ean?.toLowerCase().includes(normalizedQuery) ||
        product.name?.toLowerCase().includes(normalizedQuery) ||
        product.business_description?.toLowerCase().includes(normalizedQuery);

      const matchesFilter = activeFilters[normalizedCategory] ?? true;

      return matchesSearch && matchesFilter;
    });
  }, [products, searchQuery, activeFilters, normalizeString]);

  useEffect(() => {
    onFilter(filteredProducts);
  }, [filteredProducts, onFilter]);

 // Dynamiczne generowanie listy kategorii
const categories = useMemo(() => {
  return Array.from(
    new Set(
      products.map((product) => {
        const category = normalizeString(product.business_category);

        // Obsługa wyjątków dla kategorii
        if (
          category === null ||
          category === "" ||
          category === "#n/a" ||
          category === "undefined" ||
          category === undefined
        ) {
          return "other"; // Zastąpienie nieprawidłowych wartości
        }

        return category; // Zwrot poprawnej kategorii
      })
    )
  );
}, [products, normalizeString]);

  return (
    <div className="accordion w-100 align-items-center m-3">
      <div className="accordion-item">
        <h2 className="accordion-header">
          <button
            className={`accordion-button ${isAccordionOpen ? "" : "collapsed"}`}
            onClick={() => setIsAccordionOpen((prev) => !prev)}
          >
            Filters
          </button>
        </h2>
        <div
          className={`accordion-collapse collapse ${isAccordionOpen ? "show" : ""}`}
        >
          <div className="accordion-body align-items-center justify-content-center d-flex">
            <div className="search-and-filter mb-4">
              <div className="mb-3">
                <input
                  type="text"
                  placeholder="Search by SKU, EAN, or Name"
                  value={searchQuery}
                  onChange={handleSearchChange}
                  className="form-control"
                />
              </div>
              <div className="d-flex justify-content-center mb-3">
                <button className="btn btn-link p-0 mx-3" onClick={selectAllFilters}>
                  {t("Select All")}
                </button>
                <button className="btn btn-link p-0 mx-3" onClick={deselectAllFilters}>
                  {t("Deselect All")}
                </button>
              </div>
              <div className="filter-buttons d-flex flex-wrap gap-2">
                {categories.map((normalizedCategory) => (
                  <button
                    key={normalizedCategory}
                    onClick={() => toggleFilter(normalizedCategory)}
                    className={`btn btn-sm ${
                      activeFilters[normalizedCategory] ? "btn-primary" : "btn-outline-secondary"
                    }`}
                  >
                    {t(normalizedCategory)} {/* Dynamiczne tłumaczenie */}
                  </button>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchAndFilter;
